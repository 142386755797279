<template>
  <div v-if="data?.statistieken">
    <UITableResponsive :items="data.statistieken || []" class="text-center w-auto" :headers="headers">
      <template #item-Weekdag="{ item }">
        <div class="flex flex-row gap-1">
          <span v-if="item.day_of_week">{{ dayOfWeekString(item.day_of_week) }}</span>
          <span v-if="item.month">({{ monthToString(item.month) }}</span>
          <span v-if="item.year"><span v-if="!item.month">(</span>{{ item.year }})</span>
        </div>
      </template>
      <template #item-Aantal="{ item }">
        <div>
          {{ item.aantal }}
          <small v-if="item.aantal_geannuleerd > 0" class="italic text-gray-500">(Waarvan {{ item.aantal_geannuleerd }} geannuleerd)</small>
        </div>
      </template>
    </UITableResponsive>
  </div>
</template>

<script setup>
import UITableResponsive from '@/components/UI/Table/Responsive.vue'

import { dayOfWeekString, monthToString } from '@/functions/formatDate'
import useGetApi from '@/hooks/useGetApi'

const props = defineProps({
  filters: {
    type: Object,
    required: true,
  },
})

const headers = [
  'Weekdag',
  'Aantal', // count
]

const { data } = useGetApi('/api/dashboard/stats/ritten/boekingsdagen', props.filters, { watch: true })
</script>
